import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Offer, OfferGroup, OfferInterface, ShippingInfos } from '../models/offer';
import { OfferVariant } from '../models/offer-definition';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { ContextService } from './context.service';

@Injectable({
    providedIn: 'root',
})
export class OfferService {
    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly contextService: ContextService,
    ) {}

    create(data: OfferInterface): Observable<Offer> {
        return this.apiService.post('/api/offers', data).pipe(map((res) => new Offer(res)));
    }

    getUserOffer(user: User): Observable<Offer[]> {
        return this.apiService.get<Offer[]>('/api/users/' + user.id + '/offers').pipe(map((data) => data.map((offer) => new Offer(offer))));
    }

    get(id: number) {
        return this.apiService.get('/api/offers/' + id).pipe(map((data) => new Offer(data)));
    }

    getContactInfos(id: number) {
        return this.apiService.post<{ phone: string; email: string }>('/api/offers/' + id + '/contact', {}, true);
    }

    getPromoted() {
        return this.apiService
            .get<Offer[]>('/api/offers/promoted?platform=' + this.contextService.getPlatform())
            .pipe(map((res) => res.map((data) => new Offer(data))));
    }

    removeOffer(offer: Offer) {
        return this.apiService.delete('/api/offers/' + offer.id);
    }

    createGroup() {
        return this.apiService.post('/api/offer-groups', {}).pipe(map((res) => new OfferGroup(res)));
    }

    getGroup(groupId: number) {
        return this.apiService.get<Offer[]>('/api/offer-groups/' + groupId + '/offers').pipe(map((res) => res.map((o) => new Offer(o))));
    }

    getVariantName(data: any): Observable<{ id: number; name: string; value: string }[]> {
        return this.apiService.post<{ id: number; name: string; value: string }[]>('/api/offer-variants/name', data);
    }

    updateOffer(
        id: number,
        newValues: {
            name: string;
            description: string;
            referencePrice: string;
            referenceCurrency: string;
            variants: {
                price: number;
            }[];
        },
    ) {
        return this.apiService.put('/api/offers/' + id, newValues).pipe(map((res) => new Offer(res)));
    }

    getShippingEstimations(offer: Offer, offerVariant: OfferVariant = null, currency: string): Observable<ShippingInfos> {
        if (!offerVariant) {
            return this.apiService.get<ShippingInfos>('/api/offers/' + offer.id + '/shipping-estimations?currency=' + currency);
        } else {
            return this.apiService.get<ShippingInfos>(
                '/api/offer/' + offer.id + '/variants/' + offerVariant.id + '/shipping-estimations?currency=' + currency,
            );
        }
    }

    checkSkuAvailability(sku: string[]): Observable<{ available: boolean; usedSku: string[] }> {
        return this.apiService.post<{ available: boolean; usedSku: string[] }>('/api/sku', { sku });
    }
}
